import { TicketFilterType } from '../../../core/enums/ticket-filter-type';
import { TicketInterface } from './indexModel';
import { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../../core/context/socket-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetAllTicketsInterface, TicketTotalsInterface, TokenInterface } from '../../../services/requestsInterfacesModel';
import { getIdCompany } from '../../../store/company';
import { getIdEmployee } from '../../../store/employee';
import { getIdUser, getNameUser, getProfileId, getUserLastName } from '../../../store/user';
import { getToken } from '../../../store/token';
import { getMute, getOrderByList, getShowConfirmationModal, getShowEditTicketsModal, getShowMeetButton, getSortByList, getSoundNotification, getUpdateButtonNumberOfTickets, setOrderByList, setShowAlertFeedback, setShowConfirmationModal, setShowEditTicketsModal, setUpdateButtonNumberOfTickets, setSortByList } from '../../../store/internal';
import { getOwner_id } from '../../../store/callcenter';
import BackendConstants from '../../../core/constants/backend-constants';
import { EmailContext } from '../../../core/context/cc-cco-context';
import UserService from '../../../services/user-service';
import { AppRequesterController } from '../../../services/appRequester/appRequesterController';
import { ListName, OrderBy, SortBy } from '../../../core/enums/order-sort-by';
import { TicketStatus } from '../../../core/enums/ticket-status';
import constsRouters from '../../../routes/constsRouter';
import HomeTickets from '.';
import { verifyCode } from '../../../services/codeCxpressInterface';
import constsApi from '../../../services/constsApi';
import { Channels } from '../../../core/enums/channels';
import { BlockTicketContext } from '../../../core/context/block-ticket-context';
import { useAppContext } from '../../../providers';
import { StatusAgent } from '../../../core/enums/status-agent';

const HomeTicketsController = (props) => {
    const AppRequesterConst = new AppRequesterController();
    const socket = useContext(SocketContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getUpdateButtonNumberOfTickets: useSelector(getUpdateButtonNumberOfTickets),
            getOrderByList: useSelector(getOrderByList),
            getSortByList: useSelector(getSortByList),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        },
        mute: {
            value: useSelector(getMute)
        }
    };

    const isMaster = () => values.user.profile_id === constsApi.profile[3].id;

    const isAgent = () => values.user.profile_id === constsApi.profile[1].id;

    const [aux, setAux] = useState([]);

    const [currentTicket, setCurrentTicket] = useState(null);
    const [ticketData, setTicketData] = useState(null);
    const [currentState, setCurrentState] = useState("all");
    const [currentViewFilter, setCurrentViewFilter] = useState(TicketFilterType.All.toString());
    const [yoursTickets, setYoursTickets] = useState(false);
    const [newTickets, setNewTickets] = useState(false);
    const [numberOfAllTickets, setNumberOfAllTickets] = useState(null);
    const [numberOfYoursTickets, setNumberOfYoursTickets] = useState(null);
    const [numberOfNewTickets, setNumberOfNewTickets] = useState(null);
    const [numberOfUnreadTickets, setNumberOfUnreadTickets] = useState(null);

    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [defaultSearching, setDefaultSearching] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit] = useState(BackendConstants.limit);
    const [currentParams, setCurrentParams] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [clickNotification, setClickNotification] = useState(false)

    const [filledCreatingTicket, setFilledCreatingTicket] = useState(false);
    const [isClosingCreateTicket, setIsClosingCreateTicket] = useState(false);
    const [closeCreateTicketButton, setCloseCreateTicketButton] = useState(null);

    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState("");
    const email = useContext(EmailContext)

    const [loadingList, setLoadingList] = useState(false)
    const [noAtendence, setNoAtendence] = useState(false)

    const [/*currentOrderBy*/, setCurrentOrderBy] = useState(values.internal.getOrderByList);

    const { setTemplateMessageId } = useAppContext();

    // const somNofication = useSelector(getSoundNotification)

    // pegando backups do formulário alterado anteriormente
    useEffect(() => {
        if (localStorage?.getItem('edit_ticket_data')?.length > 0 && location?.state?.originRedirectPage === "unify_consumers" && currentTicket === null) {
            let edit_ticket_data = JSON.parse(localStorage.getItem('edit_ticket_data'));
            let currentTicketBackup = edit_ticket_data.currentTicket, ticketData = edit_ticket_data.ticketData, currentRequester = edit_ticket_data.currentRequester_user;

            ticketData = Object.assign(ticketData, { requester_user: currentRequester, requester_user_id: currentRequester.user_id });
            setCurrentTicket(currentTicketBackup);
            setTicketData(ticketData);

            navigate(null, { state: { originRedirectPage: null } });
        }
    }, [currentTicket]);

    // abrindo edição de ticket quando for restaurado o backup anterior do formulário
    useEffect(() => {
        if (currentTicket !== null && ticketData !== null) {
            dispatch(setShowEditTicketsModal(true));
        }
    }, [currentTicket, ticketData]);

    useEffect(() => {

        const beforeUnloadListener = (event: { preventDefault: () => void; returnValue: string; }) => {
            event.preventDefault();
            return event.returnValue = t("home_tickets.want_exit");//"Are you sure you want to exit?";
        };

        function checkChange() {
            if (filledCreatingTicket === true) {
                window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
            } else {
                window.removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
            }
        }

        checkChange();

    }, [filledCreatingTicket]);

    // handle close in create ticket
    useEffect(() => {
        if (isClosingCreateTicket && closeCreateTicketButton) {
            if (closeCreateTicketButton === "filterAllTickets" || closeCreateTicketButton === "buttonCloseWithoutSaving") {
                filtersClick(TicketFilterType.All);
            } else if (closeCreateTicketButton === "filterYourTickets") {
                filtersClick(TicketFilterType.Your);
            } else if (closeCreateTicketButton === "filterUnreadTickets") {
                filtersClick(TicketFilterType.Unread);
            } else if (closeCreateTicketButton === "filterNewTickets") {
                filtersClick(TicketFilterType.New);
            }
        }
    }, [isClosingCreateTicket, closeCreateTicketButton]);

    useEffect(() => {
        const controller = new AbortController();

        if (currentState !== "reseting") {
            /**
             * default searching is false and location search exist, then default searching seted to true
             */
            setCurrentParams((params) => {
                // adiciona os parametros vindo da dashboard para que os tickets venham filtrados
                if (localStorage?.getItem('loadedHomeTicket') === "true" && location && location.state && location.state['params'] !== undefined && location.state['params'].length > 0) {
                    let paramsFromDashboard = JSON.parse(location.state['params']);
                    let paramsToGet = {};

                    if (paramsFromDashboard?.channel) {
                        paramsToGet['channel'] = paramsFromDashboard.channel;
                    }

                    if (paramsFromDashboard.start_date) {
                        paramsToGet['start_date'] = paramsFromDashboard.start_date;
                    }

                    if (paramsFromDashboard.end_date) {
                        paramsToGet['end_date'] = paramsFromDashboard.end_date;
                    }

                    if (paramsFromDashboard?.employee) {
                        paramsToGet['employee'] = paramsFromDashboard.employee;
                    }

                    if (paramsFromDashboard?.sector) {
                        paramsToGet['sector'] = paramsFromDashboard.sector;
                    }

                    if (paramsFromDashboard?.status) {
                        paramsToGet['status'] = paramsFromDashboard.status;
                    }

                    params = Object.assign(params, paramsToGet);
                    localStorage.setItem('loadedHomeTicket', 'false');
                }

                const filterFromCreatePage = localStorage.getItem("viewFilterState");

                if (location && location.state && location.state["search"] !== "" && location.state["search"] !== undefined && defaultSearching === false) {
                    setCurrentFilterSearch(location.state["search"]);
                    setDefaultSearching(true);
                    setCurrentPage((state) => {
                        // setCurrentParams((state2) => {
                            getAllTicketsToPagination(Object.assign(params, { limit: currentLimit, page: state, search: location.state["search"] }), controller, values);
                            /* return state2;
                        }); */
                        return state;
                    });
                } else if (filterFromCreatePage) { // executar uma unica vez depois de vir da create page
                    if (filterFromCreatePage === TicketFilterType.Your) {
                        filtersClick(TicketFilterType.Your);
                    } else if (filterFromCreatePage === TicketFilterType.New) {
                        filtersClick(TicketFilterType.New);
                    } else if (filterFromCreatePage === TicketFilterType.All) {
                        filtersClick(TicketFilterType.All);
                    } else if (filterFromCreatePage === TicketFilterType.Unread) {
                        filtersClick(TicketFilterType.Unread);
                    }
                    localStorage.setItem("viewFilterState", "");
                } else {
                    // while isClosingCreateTicket is false, close action current finalized and performed
                    if (values.internal.getShowEditTicketsModal === false && !isClosingCreateTicket) {
                        setOpenModalPhone(undefined);
                        setCurrentPage((state) => {
                            // setCurrentParams((state2) => {
                                getAllTicketsToPagination(Object.assign(params, { limit: currentLimit, page: state }), controller, values);
                                /* return state2;
                            }); */
                            return state;
                        });
                    }
                }

                return params;
            });
        } else {
            setCurrentState("");
        }

        return () => {
            controller.abort();
        }

    }, [currentPage, currentViewFilter, currentState, values.internal.getShowEditTicketsModal,/* callCenterState.session.register_status*/]);

    // useEffect(() => {
    //     const controller = new AbortController();
    //     if (values.internal.getUpdateButtonNumberOfTickets && values.internal.getShowEditTicketsModal === false) {
    //         updateCurrentNumberOfTickets(controller);
    //     }

    //     return () => {
    //         controller.abort();
    //     }

    // }, [values.internal.getUpdateButtonNumberOfTickets]);

    useEffect(() => {
        socket.socket?.on('new-consumer-message', (data) => {
            onSocketUpdateTicketList(data.ticket ? data.ticket : data)
            setClickNotification(true)
            dispatch(setUpdateButtonNumberOfTickets(true))
        })
        socket.socket?.on('ticket-deleted', (data) => onSocketDeleteList(data))
        socket.socket?.on('ticket-update', (data) => onSocketUpdateTicketList(data.ticket? data.ticket : data))
        socket.socket?.on('tickets-expired', (data) => OnSocketResetExpiredTicket(data))
        socket.socket?.on("ticket-totals", (data) => updateButtonCount(data))
        socket.socket?.on("ticket-totals-employee", (data) => updateButtonCount(data))
        socket.socket?.on("who-is-viewing", (data) => osWhoIsViewingUpdate(data))
        socket.socket?.on("update-counters", (data) => updateButtonCount(data, true))
        return () => {
            socket.socket?.off("new-consumer-message", onSocketUpdateTicketList)
            socket.socket?.off("ticket-deleted", onSocketDeleteList)
            socket.socket?.off("ticket-update", onSocketUpdateTicketList)
            socket.socket?.off("tickets-expired", OnSocketResetExpiredTicket)
            socket.socket?.off("ticket-totals", updateButtonCount)
            socket.socket?.off("ticket-totals-employee", updateButtonCount)
            socket.socket?.off("who-is-viewing", osWhoIsViewingUpdate)
            socket.socket?.off("update-counters", updateButtonCount)
        }
    }, [socket.socket?.active])

    // from create ticket home
    useEffect(() => {
        if (location && location.state && location.state['urlViewTicket']) {
            window.history.replaceState({}, document.title, location.state['urlViewTicket']);
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.feedback_ticket_not_founded"), signalIcon: false }));
        }
        /**
         * search from create ticket page
         */
        /* if (location && location.state && location.state["search"] !== "") {
            setCurrentFilterSearch(location.state["search"]);
            setDefaultSearching(true);
        } */
    }, []);

    const OnSocketResetExpiredTicket = (data: any) => {
        filteringTicketsExpired(null, currentParams, '', data);
    }

    // const updateCurrentNumberOfTickets = async (controller: AbortController) => {
    //     const signal = controller.signal;

    //     const headers = UserService.getHeaders()

    //     await AppRequesterConst.Get(
    //         '/ticket/totals', { headers, signal },
    //         (response: Object) => { },
    //         (data: TicketTotalsInterface) => {
    //             if (data.status === 200) {
    //                 setValuesOfNumbersTickets(data.data.total, data.data.total_my_tickets, data.data.total_new_tickets);
    //             }
    //         },
    //         (error: { response: { status: number; data: { code_cxpress: number } } }) => {
    //             if (error?.response?.status === 401) {
    //                 if (error.response?.data?.code_cxpress === 1011) {
    //                     return;
    //                 } else {
    //                     dispatch(setShowAlertFeedback({ message: verifyCode(error.response?.data?.code_cxpress, t), visibility: true, signalIcon: false }))
    //                 }                    
    //             }

    //         }, navigate, dispatch, null, { values: values }
    //     );

    //     dispatch(setUpdateButtonNumberOfTickets(false));
    // }

    const updateButtonCount = (data, update?: boolean) => {
        if (update) {
            if (data?.total) {
                setNumberOfAllTickets((prevState) => prevState + data?.total);
            }
            if (data?.total_new_tickets)
                setNumberOfNewTickets((prevState) => prevState + data.total_new_tickets);

            if (data?.total_my_tickets)
                setNumberOfYoursTickets((prevState) => prevState + data.total_my_tickets);

            if (data?.total_not_read_tickets)
                setNumberOfUnreadTickets((prevState) => prevState + data.total_not_read_tickets);
        } else {
            if (data?.total)
                setNumberOfAllTickets(data.total);

            if (data?.total_new_tickets)
                setNumberOfNewTickets(data.total_new_tickets);

            if (data?.total_my_tickets)
                setNumberOfYoursTickets(data.total_my_tickets);

            if (data?.data?.unread_tickets)
                setNumberOfUnreadTickets(data.data[0].total_not_read_tickets);
        }
    }


    const osWhoIsViewingUpdate = (data) => {
        setAux((prevState) => {
            let newAux = [];
            if (prevState) {
                newAux = prevState.map((item) => {
                    if (item && (item?.id === data?.ticket_id)) {
                        item.who_is_viewing = data?.who_is_viewing;
                    }
                    return item;
                });
                return newAux.filter((item) => {
                    return item && (item?.status_id !== TicketStatus.Resolved)
                });
            }
        })
    }

    const onSocketUpdateTicketList = async (ticket: ticketUpdateDTO, event?: string) => {
        const controller = new AbortController();
        // updateCurrentNumberOfTickets(controller);
        if (noAtendence === true) {
            const paramsNA = { limit: currentLimit, page: 1, status: TicketStatus.New, employee: 'NA' };
            getAllTickets(paramsNA, controller?.signal);
        }

        // remove o ticket se ele estiver finalizado, excluído ou abandonado
        if (ticket.status_id === TicketStatus.Abandoned || ticket.status_id === TicketStatus.Resolved || ticket.status_id === TicketStatus.Overdue) {
            onSocketDeleteList(ticket.id);
            // const controller = new AbortController();
            // updateCurrentNumberOfTickets(controller);
        } else {
            setCurrentOrderBy(order => {
                setCurrentParams((param) => {
                    let dateCheck = new Date(ticket?.updated_level2_at);
                    let dateStart = new Date(param["start_date"]);
                    let dateEnd = new Date(param["end_date"]);
                    dateStart = new Date(dateStart.getTime() + (1 * 86400000));
                    dateEnd = new Date(dateEnd.getTime() + (1 * 86400000));

                    if ((!param["status"] || ticket?.status_id === param["status"]) &&
                        (!param["bot"] || ticket?.is_bot === param["bot"]) &&
                        (!param["employee"] || (ticket?.assigned_employee && ticket?.assigned_employee.user_id === param["employee"])) &&
                        (!param["channel"] || ticket?.channel_id === param["channel"]) &&
                        (!param["sector"] || ticket?.sector_id === param["sector"]) &&
                        (!param["dateStart"] || (dateCheck >= dateStart && dateCheck <= dateEnd))) {

                        setAux((prevState) => {
                            let newAux = [];
                            if (ticket?.bold) {
                                let is_new = true;
                                if (prevState) {
                                    newAux = prevState.map((item) => {
                                        if (item && (item.id === ticket?.id) && item.bold === true) {
                                            item.bold = ticket?.bold;
                                            item.status_id = ticket?.status_id;
                                            // item.who_is_viewing = ticket?.who_is_viewing;
                                            item.sector = ticket?.sector
                                            item.subject = ticket?.subject
                                            item.assigned_employee = ticket?.assigned_employee
                                            is_new = false;
                                        } else if (item && (item.id === ticket?.id) && item.bold === false) {
                                            item.bold = ticket?.bold;
                                            item.status_id = ticket?.status_id;
                                            // item.who_is_viewing = ticket?.who_is_viewing;
                                            item.sector = ticket?.sector
                                            item.subject = ticket?.subject
                                            item.assigned_employee = ticket?.assigned_employee
                                            is_new = true;
                                        }
                                        if (ticket.processing) {
                                            item['processing'] = true
                                        }

                                        return item;
                                    });

                                    if (ticket?.status_id === TicketStatus.New) {
                                        dispatch(setUpdateButtonNumberOfTickets(true));
                                    }

                                    if (is_new && (ticket.status_id !== TicketStatus.Resolved && ticket.status_id !== TicketStatus.Abandoned) && ticket.updated_level2_at) {
                                        newAux = newAux.filter((item) => {
                                            return item && (item.id !== ticket?.id);
                                        });
                                        if (order === OrderBy.asc) {
                                            const concatArray = newAux.concat([ticket]).filter((item) => {
                                                return item && (item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned);
                                            });
                                            return concatArray;
                                        } else {
                                            return [ticket, ...newAux].filter((item) => {
                                                return item && (item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned);
                                            });
                                        }
                                    } else {
                                        return prevState.filter((item) => {
                                            return item && (item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned);
                                        });
                                    }

                                } else {
                                    return newAux.filter((item) => {
                                        return item && (item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned);
                                    });
                                }
                            } else {
                                if (prevState && ticket) {
                                    newAux = prevState.map((item) => {
                                        if (item && (item.id === ticket?.id)) {
                                            item.status_id = ticket?.status_id;
                                            // item.who_is_viewing = ticket?.who_is_viewing;
                                            item.bold = ticket?.bold;
                                            item.sector = ticket?.sector;
                                            item.subject = ticket?.subject;
                                            item.assigned_employee = ticket?.assigned_employee;
                                        }
                                        if (ticket.processing) {
                                            item['processing'] = true
                                        }

                                        return item;
                                    });
                                    return newAux.filter((item) => {
                                        return item && (item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned);
                                    });
                                } else {
                                    return newAux.filter((item) => {
                                        return item && (item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned);
                                    });
                                }
                            }
                        });
                    }
                    return param;
                });
                return order;
            });
        }
    }

    const onSocketDeleteList = async (ticket_id: string) => {
        if (ticket_id?.length === 1) {
            ticket_id = ticket_id[0];
        }

        setAux((prevState) => {
            if (prevState) {
                //remove o ticket caso ele esteja na lista
                let newAux = prevState.filter((item) => {
                    return item && (item.id !== ticket_id)
                })
                // atualiza total de tickets
                dispatch(setUpdateButtonNumberOfTickets(true));
                // adiciona o ticket no começo da listagem

                return [...newAux];
            } else {
                return [];
            }
        });
    }

    const getAllTickets = async (params: Object, signal: AbortSignal, viewFilter?: TicketFilterType) => {
        if (!isLoading && /* location && location.state && location.state['loadedHomeTicket'] */ localStorage?.getItem('loadedHomeTicket') !== "true") {
            if (
                values.internal.getSortByList.listName === ListName.ListOfTickets &&
                (values.internal.getSortByList.sort_by !== null && (values.internal.getSortByList.sort_by === SortBy.updated_level2_date || values.internal.getSortByList.sort_by === SortBy.home_ticket_update_at )) &&
                values.internal.getOrderByList !== null
            ) {
                params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
            } else if (
                values.internal.getSortByList.listName === ListName.ListOfTickets &&
                (values.internal.getSortByList.sort_by !== null && (values.internal.getSortByList.sort_by !== SortBy.updated_level2_date && values.internal.getSortByList.sort_by !== SortBy.home_ticket_update_at )) &&
                values.internal.getOrderByList !== null
            ) {
                params = Object.assign(params, { sort_by: SortBy.updated_level2_date, order_by: values.internal.getOrderByList });
            }
    
            const headers = UserService.getHeaders()
            const config = { headers, params };

            await AppRequesterConst.Get(
                "/ticket/totals", config,

                (response: Object) => {
                },
                (data: TicketTotalsInterface) => {
                    if (data.status === 200) {
                        setLoadingList(true)
                        setValuesOfNumbersTickets(data.data.total, data.data.total_my_tickets, data.data.total_new_tickets, data.data.total_not_read_tickets);
                    }
                },
                (error: { response: { status: number; data: { message: any[]; code_cxpress: number } }, message?: string }) => {
                    if (error?.message === "canceled") {
                        return;
                    } else {
                        if (error?.response?.status === 401) {
                            if (error?.response?.data?.code_cxpress === 1011) {
                                return;
                            }
                            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                        } else {
                            if (error.response?.data?.message && error.response.data.message[0]) {
                                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                                // dispatch(setShowAlertFeedback({ message: error.response.data.message[0], visibility: true, signalIcon: false }))
                            } else {
                                dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                            }
                        }
                    }
                }, navigate, dispatch, setIsLoading, { values: values }
            )

            await AppRequesterConst.Get(
                `/ticket${viewFilter === TicketFilterType.Unread? '?was_read=false': ""}`,
                config,
                (response: Object) => {
                },
                (data: GetAllTicketsInterface) => {
                    if (data.status === 200 && data.data.tickets.length > 0) {
                        setAux((state) => {
                            // verifica se ha uma quantidade indevida de tickets
                            if (state.length + data.data.tickets.length > params['page'] * params['limit']) {
                                return data.data.tickets;
                            } else if (state.length > 0 && state.length >= 50) {
                                return backGround(data.data.tickets, state)
                                // return listValidator(state, data.data.tickets);
                            } else {
                                return data.data.tickets;
                            }
                        });
                    }
                },
                (error: { response: { status: number; data: { message: any[]; code_cxpress: number } }, message?: string }) => {
                    if (error?.response?.status === 401) {
                        if (error?.response?.data?.code_cxpress === 1011) {
                            return;
                        }
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    } else if (error?.message === "canceled") {
                        // requisição abortada
                    } else if (error?.response?.data) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading, { values: values, finallyFunction: () => {  }}
            );
        }
    }


    // const listValidator = (state: any[], tickets: Array<TicketInterface>) => {
    //     let aux2 = [];

    //     for (let i = 0; i < tickets.length; i++) {
    //         let validador = true;
    //         for (let j = 0; j < state.length; j++) {
    //             if ((state[j].id === tickets[i].id)) {
    //                 validador = false;
    //                 break;
    //             }
    //         }

    //         if (validador) {
    //             aux2.push(tickets[i]);
    //         }
    //     }

    //     let concatArrays:Array<any> = state.concat(aux2);
    //     return concatArrays;
    // }

    const onLoadingViewTicket = () => {
        setLoadingList(true)
    }

    // Aqui
    const showCreateTicket = async () => {
        navigate(constsRouters.routers.createTicket.path);
        email.setEmailCC([])
        email.setEmailCCO([])
        setTemplateMessageId(null)
    }

    const resetCloseCreateTicketState = () => {
        setFilledCreatingTicket(false);
        setIsClosingCreateTicket(false);
        setCloseCreateTicketButton(null);
    }

    const updateListfunction = () => {
        setAux((prevState) => {
            if (prevState.length > (49 * currentPage)) {
                setCurrentPage(currentPage + 1);
                return prevState;
            } else {
                return prevState;
            }
        });
    };

    const updateListAfterDelete = () => {
        setAux([]);
        setCurrentPage(1);

        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        setCurrentState("deleting");

        if (currentState === "deleting") {
            setCurrentState("");
        }
    };

    const setButtonClass = (elementName) => { /* função que seta a cor do contador de tickets */

        const button = ["filterAllTickets", "filterYourTickets", "filterNewTickets"] /* lista com os elementos */

        button.map(item => { /* verifica a lista com os nomes dos elementos, e adiciona a classe de destaque */
            item === elementName ? 
                document.getElementById(item).className = "buttonTicketsActive btn btn-primary" 
                :
                document.getElementById(item).className = "buttonTicketsHome btn btn-primary";

        })
    }

    const getAllTicketsToPagination = (params: { limit: number; page: number; search?: any; }, controller: AbortController,values, viewFilter?: TicketFilterType) => {
        const signal = controller.signal;
        
        if (currentViewFilter === TicketFilterType.Your || viewFilter === TicketFilterType.Your) {
            setYoursTickets(true);
            setNewTickets(false);
            setButtonClass("filterYourTickets")
            getAllTickets(Object.assign(params, { employee: values.user.id }), signal);
            // } else if (!clickedYourInCreateTicket) {
        } else if (currentViewFilter === TicketFilterType.All) {
            setYoursTickets(false);
            setNewTickets(false);
            setButtonClass("filterAllTickets")
            getAllTickets(params, signal);
        } else if (currentViewFilter === TicketFilterType.New) {
            setYoursTickets(false);
            setNewTickets(true);
            setButtonClass("filterNewTickets")
            getAllTickets(Object.assign(params, { status: 'daddbd66-1dd2-4008-94c9-7fcb9b1a5800', employee: 'NA' }), signal, viewFilter);
        } else if (currentViewFilter === TicketFilterType.Unread) {
            setYoursTickets(false);
            setNewTickets(false);
            getAllTickets(params, signal, TicketFilterType.Unread);
        }
    }

    const backGround = (tickets: Array<TicketInterface>, state: any[]) => {
        let result: Array<any>

        let listOfTickets = tickets

        if(listOfTickets != null){
            result = state.concat(listOfTickets)
        }
        
        return result
    }


    const setCurrentTicketFunction = (value: string) => {
        setCurrentTicket(value);
        setClickNotification(false)
    }

    const filteringTickets = (e/* : { currentTarget: { name: string; }; target: { name: string; }; } */, auxObj: {}, action?: string) => {
        setCurrentPage(1);
        setAux([]);
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        let name = "";
        if (e?.currentTarget) {
            name = e?.currentTarget.name;
        } else if (e?.target) {
            name = e?.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }
        dispatch(setShowEditTicketsModal(false));

        setCurrentParams(auxObj);
    }

    const filteringTicketsExpired = (e/* : { currentTarget: { name: string; }; target: { name: string; }; } */, auxObj: {}, action?: string, data?: any) => {
        setCurrentPage(1);
        setAux([]);
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        let name = "";
        if (e?.currentTarget) {
            name = e.currentTarget.name;
        } else if (e?.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }
        
        let isViewingExpiredTicket = data.some(item => item[0] === currentTicket);
        let isViewingExpiredTicketEmployee = data.some(item => item[1] === values.employee.id);

        if(isViewingExpiredTicket === currentTicket) {
            setTimeout(() => {
                dispatch(setShowEditTicketsModal(false));
            }, 5000);
        }

        if(isViewingExpiredTicketEmployee === values.employee.id) {
            setTimeout(() => {
                dispatch(setShowEditTicketsModal(false));
            }, 5000);
        }

        setCurrentParams(auxObj);
    }

    const filtersClick = (buttonFilterType: TicketFilterType) => {
        setCurrentPage(1);
        setAux([]);
        if (buttonFilterType === TicketFilterType.All) {
            resetCloseCreateTicketState();
        } else {
            setIsClosingCreateTicket(false);
            setCloseCreateTicketButton(null);
        }
        setCurrentViewFilter(buttonFilterType.toString());
        setCurrentState(buttonFilterType.toString());

        if (currentState === buttonFilterType) {
            setCurrentState("");
        }

        dispatch(setShowEditTicketsModal(false));

        // removendo filtros que são resetados ou não usados
        setCurrentParams((resetParams) => {
            delete resetParams['page'];
            delete resetParams['limit'];
            delete resetParams['sort_by'];
            delete resetParams['order_by'];
            delete resetParams['status'];
            delete resetParams['employee'];

            return resetParams;
        });

        (document.getElementById("SearchInput") as HTMLInputElement).value = "";

        if (aux.length !== 0) {
            if ((document.getElementById("Allcheckboxes") as HTMLInputElement) != null) {
                (document.getElementById("Allcheckboxes") as HTMLInputElement).checked = false;
            }
            for (let i = 0; i < aux.length; i++) {
                if ((document.getElementById("checkboxes" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxes" + i) as HTMLInputElement).checked = false;
                }
            }
        }

        if (document.getElementById("buttonDeleteAllSelected")) {
            document.getElementById("buttonDeleteAllSelected").style.display = 'none';
        }

        // cores dos botões
        const buttonActive = "buttonTicketsActive btn btn-primary";
        const buttonInactive = "buttonTicketsHome btn btn-primary";
        if (document.getElementById("filterAllTickets"))
            document.getElementById("filterAllTickets").className = buttonFilterType === TicketFilterType.All? buttonActive: buttonInactive;
        if (document.getElementById("filterYourTickets"))
            document.getElementById("filterYourTickets").className = buttonFilterType === TicketFilterType.Your? buttonActive: buttonInactive;
        if (document.getElementById("filterNewTickets"))
            document.getElementById("filterNewTickets").className = buttonFilterType === TicketFilterType.New? buttonActive: buttonInactive;
        if (document.getElementById("filterUnreadTickets"))
            document.getElementById("filterUnreadTickets").className = buttonFilterType === TicketFilterType.Unread? buttonActive: buttonInactive;

        if (document.getElementById("listOfTickets")) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const setValuesOfNumbersTickets = (totalAllTickets: number, totalYoursTickets: number, totalNewTickets: number, totalUnreadTickets: number) => {
        setNumberOfAllTickets(totalAllTickets);
        setNumberOfYoursTickets(totalYoursTickets);
        setNumberOfNewTickets(totalNewTickets);
        setNumberOfUnreadTickets(totalUnreadTickets);
    }

    const closeWithoutSaving = (e: { currentTarget: { id: string; }; }, navigate?) => {
        setCloseCreateTicketButton(e.currentTarget.id);

        if (filledCreatingTicket) {
            dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.alert_feedback.without_save.title"), "body": t("home_tickets.alert_feedback.without_save.body"), 'id': '', 
                buttonConfirmationText: t("home_tickets.alert_feedback.without_save.confirm"), buttonReturnText: t("home_tickets.alert_feedback.without_save.cancel") }, functionConfirmation: () => closeSavingTicket(), functionfunctionCancellation: resetPreviewClickedButton() 
            }));
            setCurrentPage(1);
        } else {
            // fields not filled
            if (e.currentTarget.id === "filterAllTickets" || e.currentTarget.id === "buttonCloseWithoutSaving") {
                filtersClick(TicketFilterType.All);
            } else if (e.currentTarget.id === "filterYourTickets") {
                filtersClick(TicketFilterType.Your);
            } else if (e.currentTarget.id === "filterNewTickets") {
                filtersClick(TicketFilterType.New);
            } else if (e.currentTarget.id === "filterUnreadTickets") {
                filtersClick(TicketFilterType.Unread);
            } else {
                closeSavingTicket();
            }

            setCurrentPage(1)
        }

        return true;
    }

    const resetPreviewClickedButton = () => {
        setCloseCreateTicketButton("");
    }

    const closeSavingTicket = () => {
        setFilledCreatingTicket((state) => {
            if (state) {
                setIsClosingCreateTicket(true);
            }

            return false;
        });
    }

    const callbackOrderByTickets = (column: string, order) => {
        setCurrentPage(1);
        setAux([]);
        setCurrentState(column + order);
        setCurrentOrderBy(order);
        
        dispatch(setOrderByList(order));
        dispatch(setSortByList({ sort_by: column === SortBy.updated_level2_date? SortBy.updated_level2_date: SortBy.home_ticket_update_at, listName: ListName.ListOfTickets }));
        
        if (document.getElementById(ListName.ListOfTickets)) {
            document.getElementById(ListName.ListOfTickets).scrollTop = 0;
        }
    }

    interface ticketUpdateDTO {
        isNewTicket?: boolean,
        updated_level2_at: string | number | Date
        status_id: string
        is_bot: boolean
        assigned_employee: { user_id: string; }
        channel_id: string
        sector_id: string
        ticket_notification: string
        bold: boolean
        id: string
        // who_is_viewing: string
        sector: { id: string; name: string }
        subject: string
        processing: boolean
    }

    return <HomeTickets 
        loadingList={loadingList} 
        isLoading={isLoading} 
        setCurrentState={setCurrentState} 
        setAux={setAux} 
        isClosingCreateTicket={isClosingCreateTicket} 
        filledCreatingTicket={filledCreatingTicket} 
        setIsClosingCreateTicket={setIsClosingCreateTicket} 
        closeWithoutSaving={closeWithoutSaving} 
        setFilledCreatingTicket={setFilledCreatingTicket} 
        setCurrentTicketFunction={setCurrentTicketFunction} 
        updateListfunction={updateListfunction} 
        numberOfAllTickets={numberOfAllTickets} 
        numberOfYoursTickets={numberOfYoursTickets} 
        numberOfNewTickets={numberOfNewTickets} 
        numberOfUnreadTickets={numberOfUnreadTickets}
        filteringTickets={filteringTickets} 
        isMaster={isMaster}
        currentFilterSearch={currentFilterSearch} 
        setCurrentFilterSearch={setCurrentFilterSearch} 
        phoneNumber={phoneNumber} 
        openModalPhone={openModalPhone} 
        setOpenModalPhone={setOpenModalPhone} 
        values={values} 
        setPhoneNumber={setPhoneNumber} 
        allFiltersClick={() => { filtersClick(TicketFilterType.All) }} 
        currentState={currentState} 
        currentTicket={currentTicket} 
        ticketData={ticketData} 
        currentParams={currentParams} 
        resetCloseCreateTicketState={resetCloseCreateTicketState} 
        onLoadingViewTicket={onLoadingViewTicket} 
        showCreateTicket={showCreateTicket} 
        cleanList={cleanList} 
        yoursTickets={yoursTickets} 
        newTickets={newTickets} 
        aux={aux} 
        updateListAfterDelete={updateListAfterDelete} 
        callbackOrderByTickets={callbackOrderByTickets} 
        t={t} 
        currentViewFilter={currentViewFilter}
        clickNotification={clickNotification}
        setClickNotification={setClickNotification}
        setNoAtendence={setNoAtendence}
        isAgent={isAgent}
    />
}

export default HomeTicketsController;