import { useState, useEffect, FC, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setShowEditConsumersModal, getShowEditTicketsModal, getOrderByList, getMute, setShowAlertFeedback, setShowModalConsumersSimilarData } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18next"

import UserService from "../../../../services/user-service";
import { DataAgentInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import BackendConstants from "../../../../core/constants/backend-constants";
import ModalEditConsumers from ".";
import { AgentData } from "../../homeAgents/indexModel";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { ModalEditCunsumersControllerInterface } from "./indexModel";
import Utils from "../../../../core/shared/utils";

const ModalEditConsumersController: FC<ModalEditCunsumersControllerInterface> = (props) => {

  const { t } = useTranslation();
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [validated] = useState(false);

  const currentLngStorage = localStorage.getItem('i18nextLng');
  const [customersName, setCustomersName] = useState("");
  const [customersEmail, setCustomersEmail] = useState("");
  const [customersCpf, setCustomersCpf] = useState("");
  const [customersPhone, setCustomersPhone] = useState(null as string || null);
  const [customersAddress, setCustomersAddress] = useState("");
  const [customersObservations, setCustomersObservations] = useState("");
  const [customersWhatshapp, setCustomersWhatshapp] = useState(null as string || null);
  const [customers_instagram, SetCustomers_instagram] = useState("");
  const [customersTwitter, setCustomersTwitter] = useState("");
  const [customers_facebook, SetCustomers_facebook] = useState("");
  const [customersYoutube, setCustomersYoutube] = useState("");
  const [customersUserId, setCustomersUserId] = useState("")
  // const [customers_input, SetCustomers_input] = useState("");
  // const [customers_textarea, SetCustomers_textarea] = useState("");

  const [showfeedback] = useState(false);
  const [showfeedbackEmail, setShowfeedbackEmail] = useState(false);
  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [showfeedbackPhone, setShowfeedbackPhone] = useState(false);
  const [showfeedbackWhatsApp, setShowfeedbackWhatsApp] = useState(false);
  const [showfeedbackCpfCnpj, setShowfeedbackCpfCnpj] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [countryPhoneCodes] = useState(Utils.countryPhoneCodes());
  const [phoneTag] = useState("phone");
  const [phoneError, setPhoneError] = useState(null);
  const [whatsappTag] = useState("whatsapp");
  const [whatsappError, setWhatsappError] = useState(null);

  const [modalShow, setModalShow] = useState(props.visibility);
  const [phoneBorder, setPhoneBorder] = useState('')
  const [whatsappBorder, setWhatsappBorder] = useState('')

  const [customFields, setCustomFields] = useState([])
  const entityId = 'b93b0740-a77d-4474-aad9-7aa0044d5654'
  const [currentLimit] = useState(BackendConstants.limit);
  const [currentPage, setCurrentPage] = useState(1);
  // const [selectedStatus, setSelectedStatus] = useState([{ label: t("home_tickets.home_create_ticket.select_options.status_new"), value: 'teste' }]);
  const [customFieldsIds, setCustomFieldsIds] = useState([])
  const [optionsAgents, setOptionsAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [optionsEmployees, setOptionsEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [customersTags, setCustomersTags] = useState([]);
  const [listTags, setListTags] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appRequesterConst = new AppRequesterController();

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    employee: {
      id: useSelector(getIdEmployee)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId),
      lastname: useSelector(getUserLastName),
    },
    token: {
      value: useSelector(getToken)
    },
    internal: {
      getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
      getOrderByList: useSelector(getOrderByList),
    }, mute: {
      value: useSelector(getMute)
    }

  };

  useEffect(() => {
    //setSelectedAgent([]);
    setModalShow(props.visibility);
    if (props.data != null && props.visibility === true) {
      setCustomersName(props.data.name ? props.data.name : '');
      setCustomersEmail(props.data.email ? props.data.email : '');
      setCustomersCpf(props.data.document_number ? props.data.document_number : '');
      setCustomersPhone(props.data.phone ? props.data.phone : null);
      setCustomersAddress(props.data.address ? props.data.address : '');
      setCustomersObservations(props.data.observations ? props.data.observations : '');
      setCustomersWhatshapp(props.data.whatsapp ? props.data.whatsapp : null);
      SetCustomers_instagram(props.data.instagram_user ? props.data.instagram_user : '');
      setCustomersTwitter(props.data.twitter ? props.data.twitter : '');
      SetCustomers_facebook(props.data.facebook_user ? props.data.facebook_user : '');
      setCustomersYoutube(props.data.youtube ? props.data.youtube : '');
      setCustomersUserId(props.data?.employee_user_id ? props.data.employee_user_id : '');
      setCustomFields(props.data.custom);
      if (props.data.custom_fields) {
        setCustomFields(props.data.custom_fields);
      }
      
      if (props.data.employee_user_id) {
        getAgent(props.data.employee_user_id);
      } else {
        setSelectedAgent([]);
      }

      if (props.data.tags) {
        setCustomersTags(props.data.tags)
      } else {
        setCustomersTags([])
      }
      getAgents();
      getAllTags();
      setShowContent(false);
    }
  }, [props.visibility, props.data]);

  useEffect(() => {
    setSelectedAgent([]);
    setWhatsappBorder('');
    setPhoneBorder('');
    setShowfeedbackPhone(false);
    setShowfeedbackWhatsApp(false);
  }, [customersPhone, customersWhatshapp]);

  useEffect(() => {
    setOptionsEmployees(props.employeesData ? props.employeesData : []);

    // const dataEmployees = props.employeesData?.filter((item) => item.value == customers_user_id);
    // setSelectedEmployees(dataEmployees ? dataEmployees : []);
  }, [props.employeesData]);

  // useEffect(() => {
  //     if (props.data != null && props.visibility === true){
  //         if (props.data.employee_user_id) {
  //             getAgent(props.data.employee_user_id);
  //         }
  //         getAgents();
  //         getAllTags();        
  //     }
  // },[props.data, props.visibility])

  const toggleContent = () => {
    setShowContent(prevState => !prevState);
    const controller = new AbortController()
    getAllCustomFields({ limit: currentLimit, page: currentPage }, controller)
    initializeTextareaStates(customFields);
  };

  const [textareaStates, setTextareaStates] = useState({});
  // const [selectStates, setSelectStates] = useState({});

  const initializeTextareaStates = (customFields) => {

    const initialState = {};
    customFields?.map((customField) => {
      initialState[customField?.custom_field_id] = customField?.value[0];
    });
    setTextareaStates(initialState);
  };

  const selectTags = (value) => {
    setCustomersTags(value);
  }

  const getAllTags = async () => {
    const headers = UserService.getHeaders();
    await appRequesterConst.Get(
      '/consumer-tag?active=true', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data?.data?.tags) {
          setListTags(data?.data?.tags)
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  // const initializeSelectStates = (customFields) => {
  //   const initialState = {};
  //   customFields.forEach((customField) => {
  //     if (customField?.type === "74bf4b70-3415-4e8a-ac81-7ebe435c1424") {
  //       if (!Array.isArray(initialState[customField?.id])) {
  //         initialState[customField?.id] = [];
  //       }
  //       customField?.options?.forEach((option) => {
  //         initialState[customField.id].push({ label: option, value: option });
  //       });
  //     }
  //   });
  //   setSelectStates(initialState);
  // };
  const getAllCustomFields = async (params: { limit: number; page: number }, controller: AbortController) => {

    const headers = UserService.getHeaders()
    const signal = controller.signal

    await appRequesterConst.Get(
      `/custom-fields`, { headers: headers, signal, params: params },
      (response: Object) => { },
      (data: any) => {
        if (data?.status === 200 && data) {
          let array = [];
          for (let index = 0; index < data.data?.custom_fields?.length; index++) {
            console.log(data?.data?.custom_fields, 'aqui')
            if (data.data.custom_fields[index]?.entity_type_id === entityId) {
              array.push({ name: data?.data?.custom_fields[index]?.name, dataType: data.data.custom_fields[index]?.custom_field_datatype_id, min: data.data.custom_fields[index].filed_size_min, max: data.data.custom_fields[index].filed_size_max, type: data?.data?.custom_fields[index]?.custom_field_type_id, id: data?.data?.custom_fields[index]?.id, options: data?.data?.custom_fields[index]?.options, default_value: data.data.custom_fields[index]?.default_value, placeholder: data.data.custom_fields[index]?.placeholder });
            }
          }
          setCustomFields(array)
        }
      },
      (error: { response: { status: number } }) => {
        if (error.response?.status === 400) {

        }
        if (error.response?.status === 401) {

        }
      }, navigate, dispatch, setIsLoading, { values: values }
    )
  }

  const changeCustomField = (customField, e) => {
    if (customField.type === "2982a738-4de3-440a-8ed1-cc1fdb51bc9b" || customField.type === "d31ebd9e-af9f-4559-b04a-729439218a6f") {
      // Use setState para atualizar o estado do textarea
      setTextareaStates((prevState) => ({
        ...prevState,
        [customField.id]: e.target.value,
      }));
      if (!customFieldsIds.some(field => field.id === customField.id)) {
        setCustomFieldsIds((prevCustomFieldId) => [...prevCustomFieldId, {id: customField.id, name: customField.name}]);
      }
    }
  }

  const hide = () => {
    dispatch(setShowEditConsumersModal(false));
    setModalShow(false);
    //setSelectedAgent([]);
    setWhatsappBorder('');
    setPhoneBorder('');
    setShowfeedbackPhone(false);
    setShowfeedbackWhatsApp(false);
    setShowfeedbackName(false);
    setShowfeedbackEmail(false);
    setShowfeedbackCpfCnpj(false)
  }

  const validate = (values) => {
    setCustomersName(values.name);
    setCustomersEmail(values.email);
    setCustomersCpf(values.cpf);
    // SetCustomers_phone(values.phone);
    setCustomersAddress(values.address);
    setCustomersObservations(values.observations);
    // SetCustomers_whatshapp(values.whatsapp);
    SetCustomers_instagram(values.instagram);
    setCustomersTwitter(values.twitter);
    SetCustomers_facebook(values.facebook);
    setCustomersYoutube(values.youtube);

    let errors = {};

    if (!values.name) {
      errors["name"] = `${t('home_consumers.modal_edit.validate.name')}`;
      setShowfeedbackName(true);
    } else {
      setShowfeedbackName(false);
    }

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
        values.email
      ) &&
      values.email
    ) {
      errors["email"] = `${t('home_consumers.modal_edit.validate.email')}`;
      setShowfeedbackEmail(true);
    } else {
      setShowfeedbackEmail(false);
    }

    let number_cpf_cnpj: number;
    if (values.cpf) {
      number_cpf_cnpj = values.cpf.replace(/\D/g, "").length;
      if(currentLngStorage === 'pt-br') {
        if (number_cpf_cnpj !== 0 && number_cpf_cnpj !== 11 && number_cpf_cnpj !== 14 && values.cpf) {
          errors["cpf"] = `${t("defaults.cpfcnpj_nif")} ${t('home_consumers.modal_register.validate.document_number')}`;
          setShowfeedbackCpfCnpj(true);
        } else {
          setShowfeedbackCpfCnpj(false);
        }
      }
    } else if(currentLngStorage === 'pt-pt') {
        if (number_cpf_cnpj !== 0 && number_cpf_cnpj !== 9 && number_cpf_cnpj !== 11 && values.cpf) {
          errors["cpf"] = `${t("defaults.cpfcnpj_nif")} ${t('home_consumers.modal_register.validate.document_number')}`;
          setShowfeedbackCpfCnpj(true);
        } else {
          setShowfeedbackCpfCnpj(false);
        }
    }

    if (phoneError !== null)
      errors[phoneTag] = phoneError;
    if (whatsappError !== null)
      errors[whatsappTag] = whatsappError;

    return errors;
  }

  const onSubmit = async (e) => {
    let JsonSend = {
      id: props.data.user_id,
      name: customersName,
      "custom_fields": [],
      "tags": []
    };

    if (customersEmail !== "") {
      JsonSend["email"] = customersEmail;
    }
    if (customersYoutube !== "") {
      JsonSend["youtube"] = customersYoutube;
    }

    if (customersCpf !== "") {
      JsonSend["document_number"] = customersCpf.replace(/\D/g, "");
    }

    // if (selectedEmployees.length !== 0) {
    //   JsonSend["employee_user_id"] = selectedEmployees[0].value
    // }

    if (customersPhone !== null && customersPhone !== "" && !countryPhoneCodes.includes(customersPhone)) {
      JsonSend["phone"] = customersPhone.replace(/\D/g, "");
    }

    if (customersTwitter !== "") {
      JsonSend["twitter"] = customersTwitter;
    }

    if (customersObservations !== "") {
      JsonSend["observations"] = customersObservations;
    }

    if (customersAddress !== "") {
      JsonSend["address"] = customersAddress;
    }

    if (customersTags?.length !== 0) {
      let auxArray = [];
      customersTags?.map((elem) => {
        if (elem?.id) {
          auxArray.push(elem.id);
        }
      })
      JsonSend['tags'] = auxArray;
    }

    if (customersWhatshapp && customersWhatshapp !== "" && !countryPhoneCodes.includes(customersWhatshapp)) {
      JsonSend["whatsapp"] = customersWhatshapp.replace(/\D/g, "");
    }

    if (customFieldsIds) {
      customFieldsIds.map((customFieldId) => {
        JsonSend["custom_fields"].push({ custom_field_id: customFieldId.id, value: textareaStates[customFieldId.id] ? [textareaStates[customFieldId.id]] : [], field_name:customFieldId.name })
      })
    }

    if (selectedAgent.length > 0) {
      JsonSend["employee_user_id"] = selectedAgent[0].value;
    }

    customers_facebook != "" ? JsonSend["facebook_user"] = customers_facebook : JsonSend["facebook_user"] = null

    customers_instagram != "" ? JsonSend["instagram_user"] = customers_instagram : JsonSend["instagram_user"] = null

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + props.token,
    };

    await appRequesterConst.Put(
      '/consumer', JsonSend, { headers },
      (response: Object) => {
        return response;
      },
      (data: { data: { consumer: { user_id: string; }; ConsumersDuplicates: any[] }; }) => {
        if (data?.data?.ConsumersDuplicates) {
          dispatch(setShowAlertFeedback({ message: `${t('home_consumers.modal_edit.duplicate_consumers')}`, visibility: true, signalIcon: false }));
          dispatch(setShowModalConsumersSimilarData(true));
          hide();

          // listagem de consumidores similares

          props.setCurrentConsumer(Object.assign(JsonSend, { consumer_id: props?.data?.id }));
          props.setSimilarConsumers(data.data.ConsumersDuplicates);
        } else {
          if (props.addRequesterNotInOptions) {
            props.addRequesterNotInOptions({ user_id: props?.data?.user_id, });
          }
          dispatch(setShowAlertFeedback({ message: t("home_consumers.list_of_consumers.consumer_edit_success"), visibility: true, signalIcon: true }));
          if (props.callback !== undefined) {
            props.callback(data.data.consumer.user_id);
          }
          hide();
        }
      },
      (error: { response: { status: number; data: { message: { message: string, path: []; }, code_cxpress: number; }; }; }) => {
        if (error.response.status === 400) {
          if (error.response.data.code_cxpress === 1414) {
            dispatch(setShowAlertFeedback({ message: t("home_consumers.modal_register.error_invalid_tags"), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        } else if (error.response.status === 422) {
          if (error.response.data.message !== undefined) {
            if (error.response.data.message[0]?.path[0] === "phone") {
              setPhoneBorder('1px solid red')
              setShowfeedbackPhone(true)
            } else if (error.response.data.message[0]?.path[0] === "phone") {
              setWhatsappBorder('1px solid red')
              setShowfeedbackWhatsApp(true)
            } else if (error.response.data.message[0]?.path[0] === "document_number") {
              dispatch(setShowAlertFeedback({ message: t('errors.invalidDocument'), visibility: true, signalIcon: false }));
            }
          } else {
            dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
            // dispatch(setShowAlertFeedback({ message: t("home_consumers.modal_register.document_number_error"), visibility: true, signalIcon: false }));
          }
        } else {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
        }
        // if (error.response && error.response.data.message[0].context.label == "phone") {
        //   dispatch(setShowAlertFeedback({ message: "Número de telefone inválido.", visibility: true, signalIcon: false }));
        // } else if (error.response && error.response.data.message) {
        //   dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
        // } 
        // else {
        //   dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
        // }
      }, navigate, dispatch, setIsLoading
    );
  }

  const getAgents = async () => {
    const headers = UserService.getHeaders();
    await appRequesterConst.Get(
      '/agent', { headers },
      (response: Object) => {
        return response;
      },
      (data: DataAgentInterface) => {
        if (data.status === 200) {
          setOptionsAgents(data?.data?.employees?.length > 0 ? data?.data?.employees?.map((item) => ({ label: `${item?.name} ${item?.lastname ? item?.lastname : ""}`, value: item?.user_id })) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getAgent = async (userID: string) => {
    const headers = UserService.getHeaders()

    await appRequesterConst.Get(
      '/agent/' + userID, { headers },
      (response: Object) => {
      },
      (data: AgentData) => {
        if (data.status === 200 && data?.data?.employees) {
          setSelectedAgent([{ label: data.data.employees[0].name, value: data.data.employees[0].user_id }])
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

    const returnLanguage = () => {
        if (currentLngStorage === 'pt-br') {
          return 'PT-BR'
        } else if (currentLngStorage === 'pt-pt') {
          return 'PT-PT'
        } else if (currentLngStorage === 'en') {
          return 'UK'
        }else if (currentLngStorage === 'es') {
          return 'ES'
        }else if (currentLngStorage === 'fr') {
          return 'FR'
        }
    }

  return <ModalEditConsumers
    modalShow={modalShow}
    hide={hide}
    customersName={customersName}
    setCustomersName={setCustomersName}
    customersEmail={customersEmail}
    setCustomersEmail={setCustomersEmail}
    customersCpf={customersCpf}
    setCustomersCpf={setCustomersCpf}
    customersPhone={customersPhone}
    setCustomersPhone={setCustomersPhone}
    customersAddress={customersAddress}
    setCustomersAddress={setCustomersAddress}
    customersObservations={customersObservations}
    setCustomersObservations={setCustomersObservations}
    customersWhatshapp={customersWhatshapp}
    setCustomersWhatshapp={setCustomersWhatshapp}
    customers_instagram={customers_instagram}
    SetCustomers_instagram={SetCustomers_instagram}
    customersTwitter={customersTwitter}
    setCustomersTwitter={setCustomersTwitter}
    customers_facebook={customers_facebook}
    SetCustomers_facebook={SetCustomers_facebook}
    customersYoutube={customersYoutube}
    setCustomersYoutube={setCustomersYoutube}
    textareaStates={textareaStates}
    validated={validated}
    t={t}
    showFeedbackName={showfeedbackName}
    showFeedbackEmail={showfeedbackEmail}
    showFeedbackCpfCnpj={showfeedbackCpfCnpj}
    phoneBorder={phoneBorder}
    showfeedbackPhone={showfeedbackPhone}
    showfeedback={showfeedback}
    whatsappBorder={whatsappBorder}
    showfeedbackWhatsApp={showfeedbackWhatsApp}
    toggleContent={toggleContent}
    showContent={showContent}
    customFields={customFields}
    changeCustomField={changeCustomField}
    optionsAgents={optionsAgents}
    setOptionsAgents={setOptionsAgents}
    selectedAgent={selectedAgent}
    setSelectedAgent={setSelectedAgent}
    onSubmit={onSubmit}
    validate={validate}
    listTags={listTags}
    customers_tags={customersTags}
    selectTags={selectTags}
    selectedEmployees={selectedEmployees}
    optionsEmployees={optionsEmployees}
    setOptionsEmployees={setOptionsEmployees}
    setSelectedEmployees={setSelectedEmployees}
    returnLanguage={returnLanguage}
  />
}

export default ModalEditConsumersController