import { useEffect, useState } from "react";
import ModalRegisterSummary from ".";
import constsApi from "../../../../services/constsApi";
import { t } from "i18next";
import UserService from "../../../../services/user-service";
import { DataCompanyInterface, DataTagIdInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowRegisterSummary } from "../../../../store/internal";
import { addDays, addMonths, isAfter, isBefore, isSameDay } from "date-fns";
import { getEnableSummary, getHasSummary } from "../../../../store/company";
import { getToken } from "../../../../store/token";

const AppRequesterConst = new AppRequesterController();

export const ModalRegisterSummaryController = ({
  visibility,
  token,
  updateListfunction
}) => {

  const values: TokenInterface = {
    company: {
      configs: {
        has_summary: useSelector(getHasSummary),
        enable_summary: useSelector(getEnableSummary)
      }
    },
    token: {
      value: useSelector(getToken)
    },
  }

    useEffect(() => {
      getTags();     
    }, [])
    
    const AppRequesterConst = new AppRequesterController();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modalShow, setModalShow] = useState(visibility);
    const [isLoading, setIsLoading] = useState(false);
    const [summaryTitle, setSummaryTitle] = useState("");
    const [showfeedbackName, setShowfeedbackName] = useState(false);

    const [enableRegister, setEnableRegister] = useState(values.company.configs.enable_summary)

    useEffect(() => {
        setModalShow(visibility);
    }, [visibility]);


    // fechar o modal e limpar os campos 
    const Hide = () => {
        dispatch(setShowRegisterSummary(false));
        setModalShow(false);
        setSelectedChannel([]);
        setSelectedTags([]);
        setStartDate(null);
        setEndDate(null);
        setIsLoading(false)
    }

    // Canal
    const [channelOptions, setChannelOptions] = useState([
        { label: t("filter_option.channel_email"), value: constsApi.channel[0].id },
        { label: "Facebook", value: constsApi.channel[3].id },
        { label: "Instagram", value: constsApi.channel[4].id },
        { label: t("filter_option.channel_telephony"), value: constsApi.channel[1].id },
        { label: "Webchat", value: constsApi.channel[5].id },
        { label: "WhatsApp", value: constsApi.channel[2].id },
    ]);

    const [selectedChannel, setSelectedChannel] = useState([]);

    // Tags
    const [tagsOptions, setTagsOptions] = useState([])

    const [selectedTags, setSelectedTags] = useState([]);

    const getTags = async () => {
        const headers = UserService.getHeaders();
    
        await AppRequesterConst.Get(
          "/tag",
          { headers },
          (response: Object) => {},
          (data: DataTagIdInterface) => {
            if (data.status === 200 && data.data.tags) {
              let tags = [];
              data.data.tags.map((item) => {
                tags.push({ label: item.name, value: item.id });
              });
              setTagsOptions((state) => {
                return state.concat(tags);
              });
            }
          },
          (error: Object) => {},
          navigate,
          dispatch,
          setIsLoading,
          { values: values }
        );
      };

    // Data
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isValidDate, setIsValidDate] = useState(false)


    useEffect(() => { /* verifica se as datas de inicio e fim foram preenchidas */
      setIsValidDate((startDate && endDate))
    }, [startDate, endDate])

    const calculateMaxDate = (date) => {
      if (!date) return null;
      return addDays(date, 90);
    };

    const [selectedDate, setSelectedDate] = useState(null);

    const isDateSelectable = (date) => {
        const today = new Date();
        if (!selectedDate) {// permitir datas no passado até hoje
            
            return isBefore(date, today) && !isSameDay(date, today);
        } else {  // permitir datas até 90 dias após a data selecionada

            const maxSelectableDate = addDays(selectedDate, 90);
            return isBefore(date, today) && !isSameDay(date, today) && isAfter(date, selectedDate) && isBefore(date, maxSelectableDate);
        }
    };

    const onChangeDate = (dates: any) => {
      if (dates) {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      }
    };

    return (
        <ModalRegisterSummary
            modalShow={modalShow}
            Hide={Hide}
            channelOptions={channelOptions}
            setChannelOptions={setChannelOptions}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            tagsOptions={tagsOptions}
            setTagsOptions={setTagsOptions}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            startDate={startDate}
            endDate={endDate}
            onChangeDate={onChangeDate}
            calculateMaxDate={calculateMaxDate}
            isDateSelectable={isDateSelectable}
            token={token}
            AppRequesterConst={AppRequesterConst}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            isValidDate={isValidDate}
            enableRegister={enableRegister}
            updateListfunction={updateListfunction}
            setSummaryTitle={setSummaryTitle}
            summaryTitle={summaryTitle}
            setShowfeedbackName={setShowfeedbackName}
            showfeedbackName={showfeedbackName}
        />
    )
}

export default ModalRegisterSummaryController