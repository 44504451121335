import { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMute, getOrderByList, getShowEditTicketsModal, setRegisterCustomersModal, setShowAlertFeedback } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import UserService from "../../../../services/user-service";
import BackendConstants from "../../../../core/constants/backend-constants";
import { DataAgentInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import i18n from "../../../../i18next"
import ModalRegisterConsumers from ".";
import { ModalRegisterCustomersInterface } from "./indexModel";
import { AgentData } from "../../homeAgents/indexModel";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import Utils from "../../../../core/shared/utils";

const AppRequesterConst = new AppRequesterController();

const ModalRegisterConsumersController: FC<ModalRegisterCustomersInterface> = (props) => {

  const { t } = useTranslation();
  const [isLoading , setIsLoading] = useState(false)
  const [validated] = useState(false);

  const currentLngStorage = localStorage.getItem('i18nextLng');
  const [customers_name, SetCustomers_name] = useState("");
  const [customers_email, SetCustomers_email] = useState("");
  const [customers_cpf, SetCustomers_cpf] = useState("");
  const [customers_phone, SetCustomers_phone] = useState(null as string || null);
  const [customers_address, SetCustomers_address] = useState("");
  const [customers_observations, SetCustomers_observations] = useState("");
  const [customers_whatshapp, SetCustomers_whatshapp] = useState(null as string || null);
  const [customers_instagram, SetCustomers_instagram] = useState("");
  const [customers_twitter, SetCustomers_twitter] = useState("");
  const [customers_facebook, SetCustomers_facebook] = useState("");
  const [customers_youtube, SetCustomers_youtube] = useState("");

  const [showfeedback] = useState(false);
  const [showfeedbackEmail, setShowfeedbackEmail] = useState(false);
  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [showfeedbackPhone, setShowfeedbackPhone] = useState(false);
  const [showfeedbackWhatsApp, setShowfeedbackWhatsApp] = useState(false);
  const [showfeedbackCpfCnpj, setShowfeedbackCpfCnpj] = useState(false);

  const [countryPhoneCodes] = useState(Utils.countryPhoneCodes());
  const [phoneTag] = useState("phone");
  const [phoneError, setPhoneError] = useState(null);
  const [whatsappTag] = useState("whatsapp");
  const [whatsappError, setWhatsappError] = useState(null);

  const [modalShow, setModalShow] = useState(props.visibility);
  const [showContent, setShowContent] = useState(false);
  const [phoneBorder, setPhoneBorder] = useState('')
  const [whatsappBorder, setWhatsappBorder] = useState('')
  const [customFields, setCustomFields] = useState([])
  const entityId = 'b93b0740-a77d-4474-aad9-7aa0044d5654';
  const [currentLimit] = useState(BackendConstants.limit);
  const [currentPage, SetCurrentPage] = useState(1);
  const [textareaStates, setTextareaStates] = useState({});
  const [customFieldsIds, setCustomFieldsIds] = useState([])
  const [optionsAgents, setOptionsAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [optionsEmployees, setOptionsEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [listTags, setListTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setModalShow(props.visibility);
    setWhatsappBorder('')
    setPhoneBorder('')
    setShowfeedbackPhone(false)
    setShowfeedbackWhatsApp(false)
    
  }, [props.visibility, customers_phone, customers_whatshapp]);

  useEffect(() => {
    if (modalShow) {
      const controller = new AbortController()
      getAllCustomFields({ limit: currentLimit, page: currentPage }, controller)
      initializeTextareaStates(customFields);
      getAgents();
      getTags();
    }
  }, [setShowContent, modalShow])

  const toggleContent = () => {
    setShowContent(prevState => !prevState);
    const controller = new AbortController()
    getAllCustomFields({ limit: currentLimit, page: currentPage }, controller)
    initializeTextareaStates(customFields);
  };

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    employee: {
      id: useSelector(getIdEmployee)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId),
      lastname: useSelector(getUserLastName),
    },
    token: {
      value: useSelector(getToken)
    },
    internal: {
      getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
      getOrderByList: useSelector(getOrderByList),
    }, mute: {
      value: useSelector(getMute)
    }

  };

  const getAllCustomFields = async (params: { limit: number; page: number }, controller: AbortController) => {

    const headers = UserService.getHeaders()
    const signal = controller.signal

    await AppRequesterConst.Get(
      `/custom-fields`, { headers: headers, signal, params: params },
      (response: Object) => { },
      (data: any) => {
        if (data?.status === 200 && data) {
          let array = [];
          for (let index = 0; index < data.data?.custom_fields?.length; index++) {
            console.log(data?.data?.custom_fields, 'aqui')
            if (data.data?.custom_fields[index]?.entity_type_id === entityId) {
              array.push({ name: data?.data?.custom_fields[index]?.name, dataType: data.data.custom_fields[index]?.custom_field_datatype_id, min: data.data.custom_fields[index].filed_size_min, max: data.data.custom_fields[index].filed_size_max, type: data?.data?.custom_fields[index]?.custom_field_type_id, id: data?.data?.custom_fields[index]?.id, options: data?.data?.custom_fields[index]?.options, default_value: data.data.custom_fields[index]?.default_value, placeholder: data.data.custom_fields[index]?.placeholder });
            }
          }
          setCustomFields(array)
        }
      },
      (error: { response: { status: number } }) => {
        if (error.response?.status === 400) {

        }
        if (error.response?.status === 401) {

        }
      }, navigate, dispatch, setIsLoading, { values: values }
    )
  }

  const initializeTextareaStates = (customFields) => {
    const initialState = {};
    customFields.forEach((customField) => {
      if (customField.type === "2982a738-4de3-440a-8ed1-cc1fdb51bc9b" || customField.type === "d31ebd9e-af9f-4559-b04a-729439218a6f") {
        initialState[customField.id] = "";
      }
    });
    setTextareaStates(initialState);
  };

  const changeCustomField = (customField, e) => {
    if (customField.type === "2982a738-4de3-440a-8ed1-cc1fdb51bc9b" || customField.type === "d31ebd9e-af9f-4559-b04a-729439218a6f") {
      // Use setState para atualizar o estado do textarea
      setTextareaStates((prevState) => ({
        ...prevState,
        [customField.id]: e.target.value,
      }));

      validateField(e, customField)

      if (!customFieldsIds.some(field => field.id === customField.id)) {
        setCustomFieldsIds((prevCustomFieldId) => [...prevCustomFieldId, {id: customField.id, name: customField.name}]);
      }
    }
  }

  const validateField = (value, customField) => {
    const { type, minLength, maxLength } = customField;
    if (minLength && value.length < minLength) {
        return `Minimum ${minLength} characters required.`;
    }
    if (maxLength && value.length > maxLength) {
        return `Maximum ${maxLength} characters allowed.`;
    }
    if (type === "number" && isNaN(value)) {
        return "Please enter a valid number.";
    }
    if (type === "alphanumeric" && !/^[a-zA-Z0-9]*$/.test(value)) {
        return "Only alphanumeric characters are allowed.";
    }
    if (type === "text" && !/^[a-zA-Z\s]*$/.test(value)) {
        return "Only alphabetic characters are allowed.";
    }
    return null;
};

  const Hide = () => {
    dispatch(setRegisterCustomersModal(false));
    setModalShow(false);
    setSelectedEmployees([])
    setSelectedAgent([]);
    setWhatsappBorder('');
    setPhoneBorder('');
    SetCustomers_email("");
    SetCustomers_phone("");
    SetCustomers_youtube("");
    SetCustomers_cpf("");
    SetCustomers_twitter("");
    SetCustomers_observations("");
    SetCustomers_address("");
    SetCustomers_whatshapp("");
    setShowfeedbackPhone(false);
    setShowfeedbackWhatsApp(false);
    setShowfeedbackName(false);
    setShowfeedbackEmail(false);
    setShowfeedbackCpfCnpj(false)
  }

  const Validate = (values) => {
        let errors = {};

        if (!values.name) {
          errors["name"] = `${t('home_consumers.modal_register.validate.name')}`;
          setShowfeedbackName(true);
          } else {
          setShowfeedbackName(false);
          }

          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
              values.email
            ) &&
            values.email
          ) {
            errors["email"] = `${t('home_consumers.modal_register.validate.email')}`;
            setShowfeedbackEmail(true);
          } else {
            setShowfeedbackEmail(false);
          }

          let number_cpf_cnpj: number;
          if (values.cpf) {
            number_cpf_cnpj = values.cpf.replace(/\D/g, "").length;
            if(currentLngStorage === 'pt-br') {
              if (number_cpf_cnpj !== 0 && number_cpf_cnpj !== 11 && number_cpf_cnpj !== 14 && values.cpf) {
                errors["cpf"] = `${t("defaults.cpfcnpj_nif")} ${t('home_consumers.modal_register.validate.document_number')}`;
                setShowfeedbackCpfCnpj(true);
              } else {
                setShowfeedbackCpfCnpj(false);
              }
            }
          } else if(currentLngStorage === 'pt-pt') {
              if (number_cpf_cnpj !== 0 && number_cpf_cnpj !== 9 && number_cpf_cnpj !== 11 && values.cpf) {
                errors["cpf"] = `${t("defaults.cpfcnpj_nif")} ${t('home_consumers.modal_register.validate.document_number')}`;
                setShowfeedbackCpfCnpj(true);
              } else {
                setShowfeedbackCpfCnpj(false);
              }
          }

    if (phoneError !== null)
      errors[phoneTag] = phoneError;
    if (whatsappError !== null)
      errors[whatsappTag] = whatsappError;

    return errors;
  }

  const getAgents = async () => {
    const headers = UserService.getHeaders();
    await AppRequesterConst.Get(
      '/agent', { headers },
      (response: Object) => {
        return response;
      },
      (data: DataAgentInterface) => {
        if (data.status === 200) {
          setOptionsAgents(data.data.employees.length > 0 ? data.data.employees.map((item) => ({ label: `${item.name} ${item.lastname ? item.lastname : ""}`, value: item.user_id })) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const selectTags = (value) => {
    setCurrentTags(value);
  }
   
  const getTags = async () => {
    const headers = UserService.getHeaders();
    await AppRequesterConst.Get(
      '/consumer-tag?active=true', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        setListTags(data.data?.tags)
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getAllEmployees = async (params: { limit: number; page: number }, controller: AbortController) => {

    const headers = UserService.getHeaders()
    const signal = controller.signal

    await AppRequesterConst.Get(
      `/agent`, { headers, signal, params },
      (response: Object) => { },
      (data: AgentData) => {
        setOptionsEmployees(data.data.employees ? data.data.employees.map((item, index) => {
          return { label: item.name, value: item.user_id }
        }) : [])
      },
      (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
        /*if (error.response?.data?.message && error.response.data.message[0]) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))

        } else if (error.response.status == 500) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false}))
        } else {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false}))
        }*/
      }, navigate, dispatch, setIsLoading, { values: values }
    )
  }

  const returnLanguage = () => {
    if (currentLngStorage === 'pt-br') {
      return 'PT-BR'
    } else if (currentLngStorage === 'pt-pt') {
      return 'PT-PT'
    } else if (currentLngStorage === 'en') {
      return 'UK'
    }else if (currentLngStorage === 'es') {
      return 'ES'
    }else if (currentLngStorage === 'fr') {
      return 'FR'
    }
  }

  return <ModalRegisterConsumers
    modalShow={modalShow}
    Hide={Hide}
    textareaStates={textareaStates}
    SetCustomers_name={SetCustomers_name}
    SetCustomers_email={SetCustomers_email}
    SetCustomers_cpf={SetCustomers_cpf}
    SetCustomers_phone={SetCustomers_phone}
    SetCustomers_address={SetCustomers_address}
    SetCustomers_observations={SetCustomers_observations}
    SetCustomers_whatshapp={SetCustomers_whatshapp}
    SetCustomers_instagram={SetCustomers_instagram}
    SetCustomers_twitter={SetCustomers_twitter}
    SetCustomers_facebook={SetCustomers_facebook}
    SetCustomers_youtube={SetCustomers_youtube}
    Validate={Validate}
    customers_name={customers_name}
    customers_email={customers_email}
    customers_youtube={customers_youtube}
    customers_cpf={customers_cpf}
    customers_phone={customers_phone}
    customers_address={customers_address}
    customers_observations={customers_observations}
    customers_whatshapp={customers_whatshapp}
    customers_instagram={customers_instagram}
    customers_twitter={customers_twitter}
    customers_facebook={customers_facebook}
    customFieldsIds={customFieldsIds}
    props={props}
    AppRequesterConst={AppRequesterConst}
    dispatch={dispatch}
    navigate={navigate}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    setPhoneBorder={setPhoneBorder}
    setShowfeedbackPhone={setShowfeedbackPhone}
    setWhatsappBorder={setWhatsappBorder}
    setShowfeedbackWhatsApp={setShowfeedbackWhatsApp}
    validated={validated}
    showfeedbackName={showfeedbackName}
    showfeedbackEmail={showfeedbackEmail}
    showfeedbackCpfCnpj={showfeedbackCpfCnpj}
    showfeedbackPhone={showfeedbackPhone}
    showfeedbackWhatsApp={showfeedbackWhatsApp}
    t={t}
    phoneBorder={phoneBorder}
    showfeedback={showfeedback}
    whatsappBorder={whatsappBorder}
    toggleContent={toggleContent}
    showContent={showContent}
    customFields={customFields}
    changeCustomField={changeCustomField}
    optionsAgents={optionsAgents}
    setOptionsAgents={setOptionsAgents}
    selectedAgent={selectedAgent}
    setSelectedAgent={setSelectedAgent}
    selectedEmployees={selectedEmployees}
    optionsEmployees={optionsEmployees}
    setOptionsEmployees={setOptionsEmployees}
    setSelectedEmployees={setSelectedEmployees}
    returnLanguage={returnLanguage}
    selectTags={selectTags}
    currentTags={currentTags}
    Tags={listTags}
    countryPhoneCodes={countryPhoneCodes}
  />

}

export default ModalRegisterConsumersController;